<template>
  <div class="studentsList">
    <!-- <div class="title">
      <div class="inlineBlock"><el-button class="backButton">返回</el-button></div>
      <div class="inlineBlock titleText">日程 》 项目名称</div>
    </div> -->
    <el-card>
      <!-- 添加按钮 -->
      <div style="text-align:left;width:100%;height:50px;line-height:50px;">
        <div class="searchStudent float_left">
          <el-input clearable placeholder="请输入学号或姓名" v-model="search" @clear="getstudentslist">
            <el-button slot="append" class="el-icon-search" @click="getstudentslist"></el-button>
          </el-input>
        </div>
      </div>
      <!-- 学生列表表格 -->
      <el-table header-row-class-name="el-customHead" :data="studentslistData" stripe style="width: 100%"
        @selection-change="selectDelStudent">
        <!-- 姓名 -->
        <el-table-column type="index" label="序号">
        </el-table-column>
        <!-- 姓名 -->
        <el-table-column prop="personal_id" label="学号" width="150">
        </el-table-column>
        <!-- 姓名 -->
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <!-- 到 -->
        <el-table-column label="签到">
          <template slot-scope="scope">
            <!-- <el-tag type="success" v-if="scope.row.sign_in!='-'">√</el-tag>
            <el-tag type="info" v-else>—</el-tag> -->
            <img v-if="scope.row.sign_in != '-'" class="studentSign" src="../../../assets/image/studentsign.png" alt="">
            <div v-else>—</div>
          </template>
        </el-table-column>
        <!-- 出 -->
        <el-table-column label="签出">
          <template slot-scope="scope">
            <!-- <el-tag type="success" v-if="scope.row.sign_out!='-'">√</el-tag>
            <el-tag type="info" v-else>—</el-tag> -->
            <img v-if="scope.row.sign_out != '-'" class="studentSign" src="../../../assets/image/studentsign.png" alt="">
            <div v-else>—</div>
          </template>
        </el-table-column>
        <!-- 测试 -->
        <el-table-column prop="exam_score" label="测试">
          <template slot-scope="scope">
            <!-- <span style="cursor:pointer" @click="goToExamRecordPage(scope.row)">{{ scope.row.exam_score }}</span>  -->
            <span style="cursor:pointer" >{{ scope.row.exam_score }}</span> 
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column prop="opt_score" label="操作">
          <template slot-scope="scope">
            <!-- <span style="cursor:pointer" @click="showOptRecord(scope.row.project_id,scope.row.userid,scope.row.name,scope.row.personal_id)">{{ scope.row.opt_score }}</span>  -->
            <span style="cursor:pointer" >{{ scope.row.opt_score }}</span> 
          </template>
        </el-table-column>
        <!-- 虚拟 -->
        <el-table-column prop="exp_score"  label="虚拟">
          <template slot-scope="scope">
            <!-- <span style="cursor:pointer" @click="getExpDataList(scope.row.project_id,scope.row.userid,scope.row.name,scope.row.personal_id)">{{ scope.row.exp_score }}</span>  -->
            <span style="cursor:pointer">{{ scope.row.exp_score }}</span> 
          </template>
        </el-table-column>
        <!-- 报告 -->
        <el-table-column label="报告">
          <template slot-scope="scope">
            <div v-if="scope.row.fs == '未采用' || scope.row.report_status == 0" class="fontDark">{{scope.row.fs}}</div>
            <div v-else-if="scope.row.fs == '未答题'" class="fontDark">未填写</div>
            <div v-else-if="scope.row.fs == '未提交'" class="fontDark">未提交</div>
            <div v-else-if="scope.row.fs == '已提交'" class="cursor fontWeight">已提交</div>
            <div v-else class="cursor">{{ scope.row.fs }}</div>
          </template>
        </el-table-column>
        <!-- 总分 -->
        <el-table-column label="总分">
          <template slot-scope="scope">
            <div>{{ scope.row.final_score }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="zuowei" label="座号">
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 复制学生学号用的文本框 -->
    <div v-show="sendNoticeDialogVisible" class="sendNoticeDialog">
      <el-dialog @close="noticeDialogClose" title="发送通知" :visible.sync="sendNoticeDialogVisible">
        <div class="tips">公众号消息异步推送，班级人数过多时推送较慢，切勿重复发送！小程序消息同步推送，需用户自行查询！</div>
        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8 }" placeholder="请输入内容" v-model="noticeValue">
        </el-input>

        <span slot="footer" class="dialog-footer">
          <el-button class="themecancelButton" @click="sendNoticeDialogVisible = false">取消</el-button>
          <el-button class="themeButton" type="primary" @click="sendNotice">发送</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 显示学生操作确认弹窗 -->
    <div v-show="reductOKDialog" >
      <el-dialog :title="studentOptListTitle" :visible.sync="reductOKDialog" class="opt-reduct-dialog">
        <span>{{opt_content}}</span> : <span><el-input v-model="opt_reduct_score" placeholder="请输入内容" type="number" style="width:90px;"></el-input></span>
        <span slot="footer" class="dialog-footer">
          <el-button class="themecancelButton" @click="reductOKDialog = false">取消</el-button>
          <el-button class="themeButton" type="primary" @click="studentReductOK">确定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 显示学生操作扣分弹窗 -->
    <div v-show="studentOptReductDialogVisible" >
      <el-dialog :title="studentOptListTitle" :visible.sync="studentOptReductDialogVisible" class="opt-dialog">
        <el-table :data="projectOptList" stripe style="width: 100%">
          <el-table-column prop="des" label="描述"  ></el-table-column>
          <el-table-column prop="score" label="变动分数" width="180" >
            <template slot-scope="scope">
              <span v-if="scope.row.sign == 1">{{scope.row.score}}</span>
              <span v-if="scope.row.sign == 0">-{{scope.row.score}}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <!-- 显示学生操作记录弹窗 -->
    <div v-show="studentOptListDialogVisible" >
      <el-dialog :title="studentOptListTitle" :visible.sync="studentOptListDialogVisible" class="opt-dialog">
        <el-table :data="studentOptList" stripe style="width: 100%">
          <el-table-column prop="timestamp" label="扣分时间" width="180" ></el-table-column>
          <el-table-column prop="opt_name" label="操作内容" ></el-table-column>
          <el-table-column prop="score" label="变动分数" width="80" ></el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <!-- 显示操作列表弹窗 -->
    <div v-show="projectOptListDialogVisible" >
      <el-dialog :title="projectOptListTitle" :visible.sync="projectOptListDialogVisible" class="opt-dialog">
        <div class="project-opt">
          <!-- 内容 -->
          <span style="width:500px;"><el-input v-model="opt_des" placeholder="请输入操作详情！"></el-input></span>
          <!-- 分数 -->
          <span style="width:150px;"><el-input v-model="opt_score" placeholder="请输入操作分数" type="number"></el-input></span>
          <!-- 类型 -->
          <span style="width:120px;">
            <el-select v-model="opt_sign" placeholder="请选择">
              <el-option label="减分" value="0"></el-option>
              <el-option label="加分" value="1"></el-option>
            </el-select>
          </span>
          <!-- 按钮 -->
          <span><el-button type="primary" @click="optUpdate" style="background-color: #0D5570;width: 100px;border-color: #0D5570;">确定</el-button></span>
        </div>
        <el-table :data="projectOptList" stripe style="width: 100%">
          <el-table-column prop="des" label="规则描述"  ></el-table-column>
          <el-table-column prop="score" label="变动分数" width="100" ></el-table-column>
          <el-table-column label="加/扣分" width="120" >
            <template slot-scope="scope">
              <span v-if="scope.row.sign == '0'">扣分</span>
              <span v-else>加分</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <div class="inlineBlock outOperate" @click="getOptRecordData(scope.row)">编辑</div>
              <div class="inlineBlock delOperate" @click="delOptRecord(scope.row)">删除</div>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <!-- 虚拟实验记录列表 -->
    <el-dialog :title="studentExpDataTitle" :visible.sync="studentExpDataVisiable" class="opt-dialog expdatalist">
      <el-table :data="studentExpDataList" stripe style="width: 100%;height: 57vh !important;" >
        <el-table-column prop="subtime" label="关卡提交时间"  ></el-table-column>
        <el-table-column prop="score" label="分数"  ></el-table-column>
        <el-table-column label="模式"  >
          <template slot-scope="scope">
            <span v-if="scope.row.mode == '1'">练习模式</span>
            <span v-if="scope.row.mode == '2'">考核模式</span>
            <span v-if="scope.row.mode == '3'">游客模式</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="耗时/秒"  ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
// 引入学生列表表格组件
import StudentsList from "@/assets/js/teacher/calendar/studentsList.js";
export default {
  ...StudentsList
}
</script>
<style lang="scss">
.expdatalist{
  .el-table {
    height: 57vh !important;
  }
  .el-dialog__body {
    padding: 17px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}

.studentsList {
  .el-dialog {
    width: 27.5rem !important;
    height: 30rem !important;
    border-radius: 12px !important;
  }
  .el-dialog__title {
    font: normal normal normal 1.125rem Source Han Sans CN;
  }
  .el-dialog__headerbtn {
    font-size: 1.4375rem;
  }
  .opt-dialog{
    .el-dialog{
      width: 62.5rem !important;
      height: 40rem !important;
      .el-table{
        height: 50vh !important;
      }
    }
  }
  .opt-reduct-dialog{
    .el-dialog{
      width: 50rem !important;
      height: auto !important;
    }
    .dialog-footer{
      font-size: 16px;
    }
  }
}
.opt-title{
  position: absolute;
  top: 22px;
  right: 140px;cursor:pointer;color:#0D5570;font-size:16px
}

.studentsList .sendNoticeDialog .el-dialog {
  width: 47rem !important;
  height: 20rem !important;
}

.studentsList .addStudentDialog .el-dialog {
  width: 47rem !important;
  height: auto !important;
}

.studentsList .delStudentDialog .el-dialog,
.studentsList .delmanyStudentDialog .el-dialog,
.studentsList .allSignStudentDialog .el-dialog,
.studentsList .allOutStudentDialog .el-dialog {
  width: 27.5rem !important;
  height: 13.5rem !important;
}

.studentsList .addStudentDialog .el-dialog__body {
  padding: 25px 20px;
}

.studentsList .delStudentDialog .el-dialog__body,
.studentsList .delmanyStudentDialog .el-dialog__body .studentsList .allSignStudentDialog .el-dialog__body,
.studentsList .allOutStudentDialog .el-dialog__body {
  padding-top: 1.875rem !important;
  height: 5.625rem;
  /* padding: 30px 20px; */
  color: #606266;
  font-size: .875rem;
  word-break: break-all;
}

.el-customHead .el-table_1_column_1,
.el-customHead .el-table_1_column_2,
.el-customHead .el-table_1_column_3,
.el-customHead .el-table_1_column_4,
.el-customHead .el-table_1_column_5,
.el-customHead .el-table_1_column_6,
.el-customHead .el-table_1_column_7,
.el-customHead .el-table_1_column_8,
.el-customHead .el-table_1_column_9,
.el-customHead .el-table_1_column_10,
.el-customHead .el-table_1_column_11 {
  background-color: #F0EFF4 !important;
}

.el-customHead .el-table_1_column_1::after {
  position: absolute;
  left: 1.975rem;
  top: 0.9rem;
}

.studentsList .searchStudent .el-input__inner {
  height: 100% !important;
  line-height: 100% !important;
}

.studentsList .searchStudent .el-input {
  height: 100%;
  float: left;
}

.studentsList .searchStudent {
  width: 12.6042vw;
  height: 2.0833vw;
  margin-left: .5vw;
}

.studentsList .addStudentDialog .el-table {
  max-height: 16.625rem;
  overflow: auto;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0D5570;
  border-color: #0D5570;
}

.studentsList .el-tabs__item.is-active {
  color: #0D5570;
}

.studentsList .el-tabs__active-bar {
  background-color: #0D5570;
}

.cell {
  -webkit-user-select: text;
}
</style>
<style lang='scss' scoped >
@import "@/assets/css/teacher/calendar/StudentsList.scss";
</style>